import mapboxgl from 'mapbox-gl';  // eslint-disable-line import/no-webpack-loader-syntax
import{ useContext, useEffect, useRef } from 'react';
import { MapContext } from '../../../contexts/MapContext';

const secondsPerRevolution = 50;
// Above zoom level 5, do not rotate.
const maxSpinZoom = 5;
// Rotate at intermediate speeds between zoom levels 3 and 5.
const slowSpinZoom = 3;

mapboxgl.accessToken =
  "pk.eyJ1IjoiemFnb3JueTk1IiwiYSI6ImNsMDZwdnN3NzAwNGwzY3M3a2hhenVhNWMifQ.W3gFW3Nl8kWsRkWtfDv6Ng";


export const Map = () => {

    const {loadMap, map,appLayout} = useContext(MapContext)
    const mapContainer = useRef<HTMLDivElement>(null);
    const mapPresent = useRef<boolean>(false)
    const mapInitializing = useRef<boolean>(false)
    const userInteracting = useRef<boolean>(false)
    const spinEnabled = useRef<boolean>(true)

    const handleUserInteracting = () => {
        userInteracting.current = true;
    }

    const handleMoveEnd = (map:mapboxgl.Map) => {
        userInteracting.current = false;
        spinGlobe(map)
    }

    const handleSpinGlobe = (map:mapboxgl.Map) => {
        spinGlobe(map)
    }

    const spinGlobe = (map:mapboxgl.Map) =>{
        const zoom = map.getZoom();

        if (spinEnabled.current && !userInteracting.current && zoom < maxSpinZoom) {
            let distancePerSecond = 360 / secondsPerRevolution;
            if (zoom > slowSpinZoom) {
            // Slow spinning at higher zooms
            const zoomDif = (maxSpinZoom - zoom) / (maxSpinZoom - slowSpinZoom);
            distancePerSecond *= zoomDif;
            }
            const center = map.getCenter();
            center.lng -= distancePerSecond;
            // Smoothly animate the map over one second.
            // When this animation is complete, it calls a 'moveend' event.
            map.easeTo({ center, duration: 1000, easing: (n:any) => n });
        }
    }

    useEffect(() => {

        if(mapPresent.current) return

        console.log('map initializing')

        mapPresent.current = true

        const containerWidth = mapContainer.current!.clientWidth

        const newMap = new mapboxgl.Map({
            container: mapContainer.current!, // container ID
            style: 'mapbox://styles/mapbox/streets-v12', // style URL
            center: [0, 0], // starting position [lng, lat]
            zoom: 0, // starting zoom,
            bearing: -11,
        });

        newMap.setPadding({
            left: appLayout === "desktop" ?containerWidth/2:0,
            right: 0,
            top: 0,
            bottom: 0
        })

        newMap.on('load', () => {
            
            loadMap(newMap)
            mapContainer.current!.style.visibility = 'visible'
            mapContainer.current!.style.opacity = '1'

            if(appLayout === "desktop"){

                newMap.setFog({
                    "horizon-blend":0.25,
                    "space-color":"#e5e4df", //#2eabf3 #e5e4df
                    "color":"hsla(177,23%,21%,0.60)", //hsla(177,23%,21%,0.60)
                    "high-color":"rgba(255,255,255,0.5)",
                    "star-intensity":0.2
                })
            }


            // newMap.flyTo({
               
            //     center: [-180, 0],
            //     zoom: appLayout === "desktop"?1.5:1,
            //     duration: 2000,

            // })

            newMap.on("mousedown",handleUserInteracting);
            newMap.on("wheel",handleUserInteracting);
            newMap.on("zoomend",()=> handleMoveEnd(newMap));
            newMap.on("mouseup",()=> handleMoveEnd(newMap));
            newMap.on("dragend",()=> handleMoveEnd(newMap));
            newMap.on("pitchend",()=>handleMoveEnd(newMap));
            newMap.on("rotateend",()=>handleMoveEnd(newMap));
            newMap.on("moveend",()=> handleSpinGlobe(newMap));
        })

              
        
        return () => {

        }

    },[])

    useEffect(() => {
            
            if(!map) return
    
            map.setPadding({
                left: appLayout === "desktop" ?mapContainer.current!.clientWidth/2:0,
                right: 0,
                top: 0,
                bottom: appLayout === "mobile" ?mapContainer.current!.clientHeight/3:0
            })
    },[appLayout])

    return (
        <div ref={mapContainer} id="map"></div>
    )


}