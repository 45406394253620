import { Feature, Polygon } from "geojson";
import { Map } from "mapbox-gl";

interface categoryPolygons {

    [key: string]: Feature<Polygon>

}

export const animateCategoryPolygons = (map:Map, categoryPolygons:categoryPolygons) => {

    return new Promise((resolve, reject) => {

        const layersToAnimate = Object.keys(categoryPolygons).map((key) => {
            return `tutorial-${key}-polygon`
        })

        let i = 0;
        const interval = setInterval(() => {
            if (i < layersToAnimate.length) {
                const layer = layersToAnimate[i];
                map.setPaintProperty(layer, 'fill-opacity', 0.5);
                map.setFilter("tutorial-places", ["==", ["get", "place_type"], layer.split("-")[1]])
                map.setPaintProperty("tutorial-places", 'circle-opacity', 1);
                map.setPaintProperty("tutorial-places", 'circle-radius', 3);
                map.setPaintProperty("tutorial-places", 'circle-stroke-opacity', 1);

                setTimeout(() => {
                    map.setPaintProperty("tutorial-places", 'circle-opacity', 0);
                    map.setPaintProperty("tutorial-places", 'circle-radius', 1);
                    map.setPaintProperty("tutorial-places", 'circle-stroke-opacity', 0);
                },500)

                i++;
            } else {
                resolve(true);
                clearInterval(interval);
            }
        }, 1000);

    })

}   