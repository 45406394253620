import { Feature, Polygon, lineString, lineChunk } from "@turf/turf";
import { LineString, FeatureCollection } from "geojson";
import { Map } from "mapbox-gl";

export const animateLineArea = (map: Map, geojson: Feature<Polygon>):Promise<boolean> => {

    return new Promise((resolve, reject) => {

        
        if(map.getLayer('tutorial-route')) return;
        
        let chunkedLineData = {
            'type': 'FeatureCollection',
            'features': [] as Feature<LineString>[]
    } as FeatureCollection<LineString>

    map.addSource('tutorial-route', {
        'type': 'geojson',
        'data': {
            'type': 'Feature',
            'properties': {},
            'geometry': {
                'type': 'LineString',
                'coordinates': []
            }
        }
    });
    
    let lineSource = map.getSource('tutorial-route') as mapboxgl.GeoJSONSource;
    
    
    map.addLayer({
        'id': 'tutorial-route',
        'type': 'line',
        'source': 'tutorial-route',
        'layout': {
            'line-join': 'round',
            'line-cap': 'round'
        },
        'paint': {
            'line-color': '#ecb242',
            'line-width': 4
        }
    });

    const line = lineString(geojson.geometry.coordinates[0]);
    
    const lineChunked = lineChunk(line, 0.05, { units: 'kilometers' });
    
    let i = 0;
    const interval = setInterval(() => {
        if (i < lineChunked.features.length) {
            const lineChunk = lineChunked.features[i];
            chunkedLineData.features.push(lineChunk);
            
            lineSource.setData(chunkedLineData);
            i++;
        } else {
            resolve(true);
            clearInterval(interval);
        }
    }, 17);
    
    }); 
}