import mapboxgl, {Map} from 'mapbox-gl'
import { ReactNode, createContext, useEffect, useRef, useState } from 'react'
import { Popup } from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css';

export const MapContext = createContext<any>({
    map:null,
    loadMap: (map:Map) => {},
    mapOccupied: false,
    setMapOccupied: (occupied:boolean) => {},
    customLayers: [],
})

export const MapProvider = ({children}:{children:ReactNode}) => {

    const [map, setMap] = useState<Map|null>()
    const [mapOccupied, setmapOccupied] = useState<boolean>(false)
    const mapPopup = useRef<Popup|null>()
    const [appLayout, setAppLayout] = useState<"mobile"|"desktop">(window.innerWidth < window.innerHeight ? "mobile" : "desktop")

    useEffect(()=>{

        const setLayout = async () => {
            const appWidth = window.innerWidth
            const appHeight = window.innerHeight

            if(appWidth > appHeight){
                setAppLayout("desktop")
            }else{
                setAppLayout("mobile")
            }
        }

        setLayout()

        window.addEventListener("resize", setLayout)

        return () => {
            window.removeEventListener("resize", setLayout)
        }

        

    },[])

    const loadMap = (map:Map) => {
        setMap(map)
    }

    const setMapOccupied = (occupied:boolean) => {
        setmapOccupied(occupied)
    }

    return (
        <MapContext.Provider value={{map,loadMap,mapOccupied,setMapOccupied,mapPopup, appLayout}}>
            {children}
        </MapContext.Provider>
    )

}