import { useRef, useState, useEffect, useContext } from 'react'
import { MapContext } from '../../../../contexts/MapContext'
import { Popup } from 'mapbox-gl'
import { Entry } from './1_Entry/1_Entry'
import { Polygon, dissolve } from '@turf/turf'
import { ZoomingArea } from './2_Zooming_Area/2_Zooming_Area'
import { SettingRequirements } from './3_Setting_Requirements/3_Setting_Requirements'
import { Feature, Point } from 'geojson'
import { Analysis } from './4_Analysis/4_Analysis'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { MapLegend } from './4_Analysis/4_Map_Legend'
import { MapResponsePayload } from '../../../../types'

interface DemoProps {

    endDemo: () =>void

}

export const Demo = (props:DemoProps) => {

    const {endDemo} = props

    const [stage, setStage] = useState(1)
    const mapTimer = useRef<NodeJS.Timeout | null>(null)
    const loadBar = useRef<HTMLDivElement>(null)
    const {map, mapPopup} = useContext(MapContext)
    const [areaData, setAreaData] = useState<{data:MapResponsePayload}>()

    useEffect(() => {

        const response = fetch("https://homelifetestbucket.s3.eu-central-1.amazonaws.com/assets/TestArea.json")
        .then(response => response.json()).then(data => setAreaData(data))


        if (map) {
            map.flyTo({
                center: areaData?.data.center,
            })

            }

    },[]) 

    return (

        <div className="frontPage-demo">

            <FontAwesomeIcon icon={faArrowLeft} className="frontPage-demo-close" onClick={endDemo}/>

            {stage === 1 && <Entry setStage={setStage} />}
            {areaData && stage === 2 && <ZoomingArea setStage={setStage} polygon={areaData.data.polygon as Feature<Polygon>}/>}
            {areaData && stage === 3 && <SettingRequirements setStage={setStage} requirements={areaData.data.requirements} />}
            {areaData && stage === 4 && <Analysis setStage={setStage} categoryPolygons={areaData.data.separated_polygons as any} places={areaData.data.places as Feature<Point>[]} />}
            {areaData && stage === 4 && <MapLegend categories={areaData.data.requirements}/>}
            <div className="frontPage-demo-progressDots" ref={loadBar}>
                <div className="frontPage-demo-progressDot" style={{ backgroundColor: stage >= 1 ? "var(--main-color)" : "white" }}></div>
                <div className="frontPage-demo-progressDot" style={{ backgroundColor: stage >= 2 ? "var(--main-color)" : "white" }}></div>
                <div className="frontPage-demo-progressDot" style={{ backgroundColor: stage >= 3 ? "var(--main-color)" : "white" }}></div>
                <div className="frontPage-demo-progressDot" style={{ backgroundColor: stage >= 4 ? "var(--main-color)" : "white" }}></div>
            </div>

        </div>

    )

}
