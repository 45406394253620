import { faChildReaching,faBus,faBasketShopping,faMartiniGlass, faBurger,faCartShopping, faGraduationCap, faLeaf, faTrainSubway, faTrainTram, faUtensils, faMugSaucer, faBeerMugEmpty,faTrain, faTree, faPhone, faGlobe, faPersonWalking, faPersonBiking, faCar } from "@fortawesome/free-solid-svg-icons";

export const IconMap = {
    "shopping": faCartShopping,
    "dining_drinking": faUtensils,
    "education":faGraduationCap,
    "green_area":faLeaf,
    "transportation":faBus,
    "subway":faTrainSubway,
    "bus_stop":faBus,
    "tram_stop":faTrainTram,
    "light_rail":faTrain,
    "restaurant":faUtensils,
    "fast_food":faBurger,
    "bar":faMartiniGlass,
    "cafe":faMugSaucer,
    "pub":faBeerMugEmpty,
    "supermarket": faCartShopping,
    "convenience": faBasketShopping,
    "school":faGraduationCap,
    "kindergarten":faChildReaching,
    "park":faLeaf,
    "forest":faTree,
    "walking":faPersonWalking,
    "bicycle":faPersonBiking,
    "driving":faCar,
}

export const PropertiesIconMap = {
    phone:faPhone,
    website:faGlobe,    
}