import { useEffect, useState } from "react"
import { Map } from "./Map/Map"
import { WelcomePanel } from "./WelcomePanel/WelcomePanel"
import { useContext } from "react"
import { MapContext } from "../../contexts/MapContext"
import { BurgerMenu } from "./BurgerMenu/BurgerMenu"
import { isReturnStatement } from "typescript"

interface FrontPageProps {

}

export const FrontPage = () => {

    const {map, setMapOccupied,appLayout} = useContext(MapContext)
    const [activePage,setActivePage] = useState(0)
    const [menuRollledOut, setMenuRolledOut] = useState(false)

    const signInButton = document.getElementById("login")
    const loginOverlay = document.getElementById("login-overlay")
    const loginOverlayClose = document.getElementById("login-exit")
    const navbarToMidpage = document.getElementById("navbar-midpage")!
    const navbarToTop = document.getElementById("navbar-home")!
    const navbarToBottom = document.getElementById("navbar-bottom")!
    const navbarPanel = document.getElementById("navbar-panel")!
    const burgerMenuButton = document.getElementById("frontPage-burgerMenu")!
    const burgerMenuButtonIcon = document.getElementById("frontPage-burgerMenu-icon")!

    

    useEffect(() => {

        if(map) {

            const containerWidth = document.getElementById("map")!.clientWidth
            const containerHeight = document.getElementById("map")!.clientHeight

            if(activePage === 1) {
                map.flyTo({
                    center: map.getCenter(),
                    zoom: appLayout === "desktop"?3:2,
                    duration: 1000,
                    padding: {
                        top: containerHeight,
                        bottom: 0,
                        left: containerWidth/2, 
                        right: containerWidth/2 
                    },
                })
            }else{
                map.flyTo({
                    center: map.getCenter(),
                    zoom: appLayout === "desktop"?1.5:0.5,
                    duration: 1000,
                    padding: {
                        top: 0,
                        bottom: appLayout === "mobile" ? containerHeight/3:0,
                        left: appLayout === "desktop" ? containerWidth/2:0, 
                        right:0 
                    },
                })
            }


        
    
    }




    }, [map, activePage])


    useEffect(() => {
        console.log(appLayout)
        console.log(menuRollledOut)
        if(appLayout === "mobile") {

                    navbarPanel.style.width = menuRollledOut ? "100%" : "auto"
                    navbarPanel.style.background = menuRollledOut ? "black" : "none"
                    navbarToTop.style.display = menuRollledOut ? "flex" : "none"
                    navbarToBottom.style.display = menuRollledOut ? "flex" : "none"
                    navbarToMidpage.style.display = menuRollledOut ? "flex" : "none"
                    signInButton!.style.display = menuRollledOut ? "none" : "flex"
                    burgerMenuButtonIcon.className = menuRollledOut ? "fa-solid fa-xmark" : "fa-solid fa-bars"
                    burgerMenuButtonIcon.style.color = menuRollledOut ? "var(--main-color)" : "black"
        }else{
            navbarPanel.style.width = "auto"
            navbarToTop.style.display = "flex"
            navbarToBottom.style.display = "flex"
            navbarToMidpage.style.display = "flex"
            signInButton!.style.display = "flex"
            navbarPanel.style.backdropFilter = "none"
        }

    },[menuRollledOut, appLayout])

    useEffect(() => {

        const setMenuRoll = () =>{
            setMenuRolledOut(state=>!state)
        }


        if (signInButton) {
            signInButton.addEventListener("click", () => {
                
                if (loginOverlay) {
                    loginOverlay.style.opacity = "1"
                    loginOverlay.style.visibility = "visible"
                }

            })
        }

        if (loginOverlayClose) {
            loginOverlayClose.addEventListener("click", () => {
                if (loginOverlay) {
                    loginOverlay.style.opacity = "0"
                    loginOverlay.style.visibility = "hidden"
                }
            })
        }

        if(burgerMenuButton) {
            burgerMenuButton.addEventListener("click", setMenuRoll)
        }

        navbarToMidpage.addEventListener("click", () => {
            setActivePage(1)
            setMenuRolledOut(false)
        })

        navbarToTop.addEventListener("click", () => {
            setActivePage(0)
            setMenuRolledOut(false)
        })

        navbarToBottom.addEventListener("click", () => {
            setActivePage(2)
            setMenuRolledOut(false)
        })

        return () => {

            burgerMenuButton.removeEventListener("click", setMenuRoll)

        }


    }, [])



    return (
        
        <>
            <WelcomePanel />
            <Map />
            {/* <BurgerMenu /> */}
        </>
        
    )

}