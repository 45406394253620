import { Map } from 'mapbox-gl';
import { Feature, Polygon } from 'geojson';
import {mask} from '@turf/turf';

export const addPolygonMask = (map: Map, geojson: Feature<Polygon>) => {

    if(map.getLayer('tutorial-mask')) return;

    map.addSource('tutorial-mask', {
        'type': 'geojson',
        'data': mask(geojson)
    });

    map.addLayer({
        'id': 'tutorial-mask',
        'type': 'fill',
        'source': 'tutorial-mask',
        'layout': {},
        'paint': {
            'fill-color': '#000',
            'fill-opacity': 0,
            'fill-opacity-transition': {
                'duration': 500
            }
        }
    });

    map.setPaintProperty('tutorial-mask', 'fill-opacity', 0.5);

}