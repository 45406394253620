import { RequirementLine } from "./requirementLine"

interface SettingRequirementsProps {

    setStage: (stage:number) => void,
    requirements: {
        [key:string]:{
            place: string,
            type: string,
            time: number,
            meters: number
        }
    }

}

export const SettingRequirements = (props:SettingRequirementsProps) => {

    const {setStage, requirements} = props

    return (

        <div className="frontPage-demo-entry">

            <h2>
                Setting Requirements
            </h2>

            <p>
                Netx step is setting up the requirements. Let's say we want to live in a place with a good access to ...
            </p>

            {Object.keys(requirements).map((key:string) => <RequirementLine key={key} requirement={requirements[key]}/>)}

            <div style={{width:"100%", display:'flex',justifyContent:"space-between"}}>
                <button className='frontPage-demo-back' onClick={()=>setStage(2)}>Back</button>
                <button className='frontPage-demo-next' onClick={()=>setStage(4)}>Next</button>
            </div>

        </div>

    )

}