interface IntroProps {

    startDemo: () => void

}

export const Intro = (props:IntroProps) => {

    const {startDemo} = props

    const loginOverlay = document.getElementById("login-overlay")

    const showLogin = () => {

        if (loginOverlay) {
            loginOverlay.style.opacity = "1"
            loginOverlay.style.visibility = "visible"
        }

    }

    return (
        <>
            <h2>
                Hello
            </h2>
            <p>
                Welcome to <b style={{color:"green"}}>Land</b><b style={{color:"var(--main-color)"}}>Scores</b>
            </p>
            <p>
                We aim to help making the <b>Best Real Estate decisions</b> around the world, with the help of our fine-tuned geospatial engines.
            </p>
            <p>
                See the live demo here, or launch the application and start exploring 🌍
            </p>

            <div className="frontPage-welcomePanel-intro-buttons">
                <button style={{backgroundColor:"var(--main-color)"}} onClick={showLogin}>Launch 🏘️</button>
                <button style={{border:"solid 1px gray", boxShadow:"none"}} onClick={()=>startDemo()}>See a live demo ▶</button>
            </div>
        </>

    )


}