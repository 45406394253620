import { Map } from "mapbox-gl";
import { Feature, Point } from "geojson";
import supportedPlaces from "./config.SUPPORTED_PLACES.json"

let colorDictionary = {} as any;

supportedPlaces.forEach((place) => {

    colorDictionary[place.place] = place.color;

});

export const preparePlaces = (map:Map, places:Feature<Point>[]) => {

    if(map.getLayer('tutorial-places')) return;

    map.addSource('tutorial-places', {
        'type': 'geojson',
        'data': {
            'type': 'FeatureCollection',
            'features': places
        }
    });

    map.addLayer({

        "id": "tutorial-places",
        "type": "circle",
        "source": "tutorial-places",
        "paint": {
            "circle-radius": 1,
            "circle-stroke-width": 1,
            "circle-stroke-color": "#000",
            "circle-color": ["get",["get", "place_type"],["literal",colorDictionary]],
            "circle-stroke-opacity": 0,
            "circle-opacity": 0,
            "circle-opacity-transition": {
                "duration": 500,
            },
            "circle-radius-transition": {
                "duration": 500
            },
            "circle-stroke-opacity-transition": {
                "duration": 500
            },
            
        }

    })

}