import { useEffect, useContext } from "react"
import { MapContext } from "../../../../../contexts/MapContext"
import { Feature, Point, Polygon } from "@turf/turf"
import { prepareCategoryPolygons } from "../../../../../Utils/prepareCategoryPolygons"
import { animateCategoryPolygons } from "../../../../../Utils/animateCategoryPolygons"
import { preparePlaces } from "../../../../../Utils/preparePlaces"

interface AnalysisProps {

    categoryPolygons:{
        [key:string]:Feature<Polygon>
    },
    setStage: (stage:number) => void,
    places: Feature<Point>[]

}

export const Analysis = (props:AnalysisProps) => {

    const {map} = useContext(MapContext)
    
    const {categoryPolygons, setStage, places} = props
    const loginOverlay = document.getElementById("login-overlay")
    
    const showLogin = () => {

        if (loginOverlay) {
            loginOverlay.style.opacity = "1"
            loginOverlay.style.visibility = "visible"
        }

    }

    useEffect(() => {

        prepareCategoryPolygons(map,categoryPolygons)
        preparePlaces(map,places)

        animateCategoryPolygons(map,categoryPolygons)

    },[])

    return (

        <div className="frontPage-demo-entry">

            <h3>Final Result</h3>

            <p>
                Now we can see exactly, where we should look for the properties to rent/buy.
            </p>

            <div style={{width:"100%", display:'flex',justifyContent:"space-between"}}>
                <button className='frontPage-demo-back' onClick={()=>setStage(3)}>Back</button>
                <button className='frontPage-demo-next' onClick={showLogin}>Launch 🏘️</button>
            </div>


        </div>

    )

}