import { placeNames, placeColors } from "../../../../../Utils/Dictionary"
import { useContext, useEffect, useState } from "react"
import { MapContext } from "../../../../../contexts/MapContext"

interface MapLegendProps {

    categories: {
        [key: string]: {
            place: string,
            type: string,
            time: number,
            meters: number
        }
    }

}

export const MapLegend = (props:MapLegendProps) => {

    const {categories} = props
    const items = Object.keys(categories).map((category:string) =>{
       return {...categories[category as keyof typeof categories], active:true}  
    })
    const [legendItems, setLegendItems] = useState(items)

    const {map} = useContext(MapContext)

    const toggleCategory = (category:string) => {

        const newLegendItems = legendItems.map((item) => {

            if(item.place === category){
                return {...item, active: !item.active}
            }else{
                return item
            }

        })

        setLegendItems(newLegendItems)

    }

    useEffect(() => {

        legendItems.forEach((item) => {

            if(item.active){
                map.setPaintProperty(`tutorial-${item.place}-polygon`, 'fill-opacity', 0.5)
            }else{
                map.setPaintProperty(`tutorial-${item.place}-polygon`, 'fill-opacity', 0)
            }
        })

    },[legendItems])



    return (

        <div className="frontPage-demo-map-legend">

            <h4>Legend</h4>

            {legendItems.map((category) => {

                const entryStyle = {
                    opacity: category.active ? 1 : 0.5
                }
                    
                    return (
    
                        <div className="frontPage-demo-map-legend-entry" key={category.place} style={entryStyle} onClick={()=>toggleCategory(category.place)}>
    
                            <div className="frontPage-demo-map-legend-entry-color" style={{backgroundColor:placeColors[category.place as keyof typeof placeColors]}}></div>
    
                            <p>{placeNames[category.place as keyof typeof placeNames]}</p>

                            <p style={{whiteSpace:"nowrap", fontSize:"0.8em"}}> | {category.time} min {category.type} range</p>
    
                        </div>
    
                    )
            })}

        </div>

    )

}