import { Feature, Polygon } from "geojson";
import { Map } from "mapbox-gl";
import supportedPlaces from "./config.SUPPORTED_PLACES.json"

interface categoryPolygons {

    [key: string]: Feature<Polygon>

}

export const prepareCategoryPolygons = (map: Map, categoryPolygons: categoryPolygons) => {

    Object.keys(categoryPolygons).forEach((key) => {

        const geojson = categoryPolygons[key];
        const color = supportedPlaces.find((place) => place.place === key)?.color;

        if (map.getLayer(`tutorial-${key}-polygon`)) return;

        map.addSource(`tutorial-${key}-polygon`, {
            'type': 'geojson',
            'data': geojson
        });

        map.addLayer({
            'id': `tutorial-${key}-polygon`,
            'type': 'fill',
            'source': `tutorial-${key}-polygon`,
            'layout': {},
            'paint': {
                'fill-color': color,
                'fill-opacity': 0,
                'fill-opacity-transition': {
                    'duration': 500,
                    "delay": 500
                }
            }
        });

    })

}