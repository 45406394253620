import React from 'react';
import logo from './logo.svg';
import './App.css';
import { MapProvider } from './contexts/MapContext';
import { FrontPage } from './components/FrontPage/FrontPage';

function App() {
  return (
    <MapProvider >
      <FrontPage />
    </MapProvider>
  );
}

export default App;
