import { Map } from "mapbox-gl";

export const ResetMap = (map: Map) => {
    
    const layers = map.getStyle().layers;

    if (layers) {
        for (let i = 0; i < layers.length; i++) {
            const layer = layers[i];
            if (layer.id.startsWith('tutorial-')) {
                map.removeLayer(layer.id)
                map.removeSource(layer.id)

            }
        }
    }

}