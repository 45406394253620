import { useContext, useEffect, useState, useRef } from 'react'
import { MapContext } from '../../../../../contexts/MapContext'
import { animateLineArea } from '../../../../../Utils/animateLineArea'
import { Feature, Polygon, bbox, bboxPolygon } from '@turf/turf'
import { ResetMap } from '../../../../../Utils/ResetMap'
import { addPolygonMask } from '../../../../../Utils/addPolygonMask'

interface ZoomingAreaProps {

    setStage: (stage: number) => void,
    polygon: Feature<Polygon>

}

export const ZoomingArea = (props:ZoomingAreaProps) => {

    const { setStage, polygon } = props

    const { map, mapPopup } = useContext(MapContext)
    const [nextReady, setNextReady] = useState(false)
    const tempTimer = useRef<NodeJS.Timeout>()

    useEffect(() => {

        if (map) {

            // map.flyTo({
            //     center: [21.017532, 52.237049],
            //     zoom: 12
            // })

            map.fitBounds(bbox(polygon) as any, {
                padding: {
                    top: 100,
                    bottom: 100,
                    left: 100,
                    right: 100
                },
                duration: 2000,
            })
            
            tempTimer.current = setTimeout(() => {
                map.once("moveend", async () => {
    
                    setNextReady(true)

                    await animateLineArea(map, polygon as Feature<Polygon>)
                    addPolygonMask(map, polygon as Feature<Polygon>)
    
                })

            }, 100)

        }

        return () => {

            if (map) {
                tempTimer.current && clearTimeout(tempTimer.current)
                
            }

        }

    },[])

    return (

        <div className="frontPage-demo-entry">

            <h3>Selecting Area</h3>
            <p>Using drawing tool we can select the area we are interested in - it can be a whole city.</p>
            {nextReady && (
                <div className="frontPage-demo-buttons">
                    <button className='frontPage-demo-back' onClick={()=>setStage(1)}>Back</button>
                    <button className='frontPage-demo-next' onClick={()=>setStage(3)}>Next</button>
                </div>
            )}

        </div>

    )

}