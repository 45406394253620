import {useEffect, useRef, useContext} from "react";
import { MapContext } from "../../../../../contexts/MapContext";
import { Popup } from "mapbox-gl";
import { ResetMap } from "../../../../../Utils/ResetMap";


interface EntryProps {

    setStage: (stage:number) => void

}

export const Entry = (props:EntryProps) => {

    const {map, mapPopup} = useContext(MapContext)

    const {setStage} = props

    useEffect(() => {

        ResetMap(map)

        if(map.getZoom() !== 1.5){

            map.flyTo({
                center: [21.017532, 52.237049],
                zoom: 1.5
            })

        }

        if(mapPopup.current){
            mapPopup.current.remove()
        }

        mapPopup.current = new Popup().setHTML(
            "<h3>Warsaw</h3><p>Poland</p>"
        )
            .setLngLat([21.017532, 52.237049])
            .addTo(map)

        return () => {

            if(mapPopup.current){
                mapPopup.current.remove()
            }

        }

    },[])


    return (

        <div className="frontPage-demo-entry">

            <h2>
                Live Demo
            </h2>

            <p>
                At first - we need to pick a starting point. Let's say we want to find a place to live in...
            </p>

            <p>...lets pick <b>Warsaw</b> 🏙️</p>
            <div style={{width:"100%", display:'flex',justifyContent:"flex-end"}}>
                <button className='frontPage-demo-next' onClick={()=>setStage(2)}>Next</button>
            </div>

        </div>

    )

}