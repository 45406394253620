import { useRef, useState, useContext } from 'react'
import { Intro } from './Intro/Intro'
import { Demo } from './Demo/Demo'
import { MapContext } from '../../../contexts/MapContext'
import { ResetMap } from '../../../Utils/ResetMap'

export const WelcomePanel = () => {
    const mainPanel = useRef<HTMLDivElement>(null)
    const introPanel = useRef<HTMLDivElement>(null)
    const [demoStarted, setDemoStarted] = useState(false)

    const {map, appLayout} = useContext(MapContext)

    const startDemo = () => {

        setDemoStarted(true)

        if (introPanel.current) {
            mainPanel.current!.style.padding = "0"
            mainPanel.current!.style.width = appLayout === "desktop" ? "30%":"100%"
            mainPanel.current!.style.paddingTop = "5em"

            introPanel.current.style.flex="1"
            introPanel.current.style.borderRadius="0"
            introPanel.current.style.background = "linear-gradient(90deg,var(--backgroundColor),#c8ceda)"
        }

    }

    const endDemo = () => {

        setDemoStarted(false)

        if (introPanel.current) {
            mainPanel.current!.style.padding = "2em"
            mainPanel.current!.style.width = "100%"
            mainPanel.current!.style.paddingTop = "0"

            introPanel.current.style.flex="initial"
            introPanel.current.style.borderRadius="5px"
            introPanel.current.style.background = "var(--backgroundColor)"
        }

        ResetMap(map)

        map.flyTo({
            center: [21.017532, 52.237049],
            zoom: appLayout === "mobile"? 0.5:1.5,
            duration: 1000,
        })

    }

    return (
        <div ref={mainPanel} className="frontPage-welcomePanel">
            <div ref={introPanel} className="frontPage-welcomePanel-intro">
                
               {!demoStarted ?<Intro startDemo={startDemo}/>:<Demo endDemo={endDemo}/>}

            </div>
        </div>
    )

}