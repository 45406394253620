import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { placeNames, transportTypes } from "../../../../../Utils/Dictionary"
import supportedPlaced from "../../../../../Utils/config.SUPPORTED_PLACES.json"
import { IconMap } from "../../../../../Utils/IconMap"

interface RequirementLineProps {

    requirement: {
        place: string,
        type: string,
        time: number,
        meters: number
    }

}

export const RequirementLine = (props: RequirementLineProps) => {

    const { requirement } = props

    return (

        <div className="frontPage-demo-requirementLine">

            <FontAwesomeIcon icon={IconMap[requirement.place as keyof typeof IconMap]} />

            <div className="frontPage-demo-requirementLine-place">
                <p>{placeNames[requirement.place as keyof typeof placeNames]} within</p>
            </div>

            <div className="frontPage-demo-requirementLine-time">
                <p>{requirement.time} min</p>
            </div>

            <div className="frontPage-demo-requirementLine-type">
                <p>{transportTypes[requirement.type as keyof typeof transportTypes]}</p>
            </div>


        </div>

    )


}