import SupportedPlaces from "./config.SUPPORTED_PLACES.json";

let colorDictionary = {} as any;

SupportedPlaces.forEach((place) => {

    colorDictionary[place.place] = place.color;

});

export const placeColors = colorDictionary;


export const placeNames = {
    transportation:"Transportation",
    shopping:"Shopping",
    dining_drinking:"Dining & Drinking",
    education:"Education",
    green_area:"Green Area",
    school:"School",
    kindergarten:"Kindergarten",
    convenience:"Convenience Store",
    supermarket:"Supermarket",
    restaurant:"Restaurant",
    fast_food:"Fast food",
    bar:"Bar",
    pub:"Pub",
    cafe:"Cafe",
    park:"Park",
    forest:"Forest",
    tram_stop:"Tram",
    bus_stop:"Bus",
    subway:"Subway",
    light_rail:"Light rail",
    bicycle:"Cycling",
    walking:"Walking",
    driving:"Driving",
  }

export const transportTypes = {

  walking:"Walking",
  driving:"Driving",
  cycling:"Cycling",

}
